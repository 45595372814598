import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

export class Notification {
  time: number;
  text: string;
  hidden = true;

  constructor(text: string, time = 3000) {
    this.text = text;
    this.time = time;
  }

  setHidden(hidden: boolean) {
    this.hidden = hidden;
  }
}

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {
  private notification: Notification;
  public notification$: BehaviorSubject<Notification> = new BehaviorSubject(null);
  timer;

  notify(notification: Notification) {
    // console.log('[notify]', notification)
    this.notification = notification;
    this.notification.setHidden(false);
    this.notification$.next(this.notification);
    this.timer = setTimeout(this.hide.bind(this), this.notification.time);
  }

  hide() {
    // console.log('[hideNotification]')
    this.notification.setHidden(true);
    this.notification$.next(this.notification);
    this.timer = clearTimeout(this.hide.bind(this));
  }
}
