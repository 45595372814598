import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './auth/guards/auth.guard';

const routes: Routes = [
  { path: '', loadChildren: '../app/home/home.module#HomeModule', pathMatch: 'full' },
  { path: 'game', loadChildren: '../app/game/game.module#GameModule' },
  { path: 'admin', loadChildren: '../app/admin/admin.module#AdminModule', canActivate: [AuthGuard] },
  { path: 'auth', loadChildren: '../app/auth/auth.module#AuthModule' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
