import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from "@angular/router";
import { Observable } from "rxjs";
import { first, map, tap } from "rxjs/operators";
import { AngularFireAuth } from "@angular/fire/auth";

@Injectable({
  providedIn: "root",
})
export class AuthGuard implements CanActivate {
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.afAuth.authState.pipe(
      map((authState) => !!authState),
      tap((authenticated) => {
        if (!authenticated) {
          this.router.navigate(["/auth/login"]);
        }
      })
    );
  }

  constructor(private afAuth: AngularFireAuth, private router: Router) {}
}
