import { Component, OnInit, HostBinding } from '@angular/core';
import { NotificationsService } from './notifications.service';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
})
export class NotificationsComponent implements OnInit {
  notification$ = this.service.notification$;

  constructor(private service: NotificationsService) { }

  ngOnInit() {

  }

}
